import { useCollection } from '@cloudscape-design/collection-hooks';
import Loading from '@risksmart-app/components/Loading';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { PageLayout } from 'src/layouts';

import { useGetResponsesSubscription } from '@/generated/graphql';

import { useColumnDefinitions } from './table.config';

const Page: FC = () => {
  const { data, loading } = useGetResponsesSubscription({
    fetchPolicy: 'no-cache',
  });
  const columnDefinitions = useColumnDefinitions();
  const { items, collectionProps } = useCollection(
    data?.third_party_response ?? [],
    {
      propertyFiltering: {
        filteringProperties: [],
        empty: <div>You have no questionnaires</div>,
      },
      sorting: {
        defaultState: {
          sortingColumn: columnDefinitions[1],
        },
      },
    }
  );

  if (loading) {
    return (
      <PageLayout>
        <Loading />;
      </PageLayout>
    );
  }

  return (
    <PageLayout
      actions={<></>}
      title={'Questionnaires'}
      meta={{
        title: 'Questionnaires',
      }}
    >
      <Table
        columnDefinitions={columnDefinitions}
        {...collectionProps}
        items={items}
        loading={false}
      />
    </PageLayout>
  );
};

export default Page;
