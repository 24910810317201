import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import Loading from '@risksmart-app/components/Loading';
import { handleError } from '@risksmart-app/components/utils/errorUtils';
import { mergeCustomI18n } from '@risksmart-app/i18n/i18n';
import i18next from 'i18next';
import { createContext, FC, ReactNode } from 'react';

import { useGetTaxonomyByLocaleAndOrgQuery } from '@/generated/graphql';

import ThirdPartyAuth0Context from './ThirdPartyAuth0Context';

type TaxonomyContext = {
  loading: boolean;
};

const TaxonomyContext = createContext<TaxonomyContext | null>(null);

const TaxonomyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useRisksmartUser(ThirdPartyAuth0Context);
  const orgKey = user?.orgKey;

  const { data, loading } = useGetTaxonomyByLocaleAndOrgQuery({
    variables: {
      Locale: i18next.language,
      OrgKey: orgKey!,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.warn(
        `Error attempting to load translations for ${orgKey}: ${i18next.language}`
      );
      handleError(error);
    },
  });
  mergeCustomI18n(data?.taxonomy_org[0]?.taxonomy);

  return (
    <TaxonomyContext.Provider
      value={{
        loading,
      }}
    >
      {loading ? <Loading /> : children}
    </TaxonomyContext.Provider>
  );
};

export { TaxonomyProvider };
