import { FileWithMeta } from '@risksmart-app/components/File/types';
import { useFileUpdate } from '@risksmart-app/components/File/useFileUpdate';
import Loading from '@risksmart-app/components/Loading';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import _, { isEmpty } from 'lodash';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { PageLayout } from 'src/layouts';

import {
  Parent_Type_Enum,
  Third_Party_Response_Status_Enum,
  useGetResponseByIdSubscription,
  useUpdateThirdPartyResponseMutation,
} from '@/generated/graphql';

import { QuestionnaireForm } from './QuestionnaireForm';
import { ResponseData } from './types';

const assignPathToFiles = (files: File[], path: string) =>
  files.map((f) => _.assignIn(f, { meta: { path } }));

// Adds the path (form field) the file belongs to as metadata.
// We have to use assignIn because the File type is protected.
const enrichFilesWithPathMetadata = (files: {
  [key: string]: File[];
}): Array<File & { meta?: { [key: string]: string } }> => {
  const filesWithMeta: Array<File & { meta?: { [key: string]: string } }> = [];
  _.keys(files).forEach((k) => {
    filesWithMeta.push(...assignPathToFiles(files[k], k));
  });

  return filesWithMeta;
};

const mapFilesToFileIds = (
  files: Array<{ file?: { Id: string } | null }> | undefined
) => {
  return files?.map((f) => {
    return { file: { Id: f.file?.Id } };
  });
};

const mapFilesWithMetaToFileIds = (files: {
  [key: string]: FileWithMeta[];
}) => {
  const fileIds: Array<{ file: { Id: string } }> = [];

  _.keys(files).forEach((k) => {
    fileIds.push(
      ...files[k].map((f) => {
        return { file: { Id: f.file.Id } };
      })
    );
  });

  return fileIds;
};

const Page: FC = () => {
  const Id = useGetGuidParam('id');

  const navigate = useNavigate();
  const [update] = useUpdateThirdPartyResponseMutation();
  const { data, loading } = useGetResponseByIdSubscription({
    fetchPolicy: 'no-cache',
    variables: { Id },
  });
  const { updateFiles } = useFileUpdate();

  const responseData = data?.third_party_response_by_pk;
  const originalFiles = responseData?.files;
  const readOnly =
    responseData?.Status === Third_Party_Response_Status_Enum.AwaitingReview ||
    responseData?.Status === Third_Party_Response_Status_Enum.Completed ||
    responseData?.Status === Third_Party_Response_Status_Enum.Recalled ||
    responseData?.Status === Third_Party_Response_Status_Enum.Rejected;

  if (loading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    );
  }

  if (
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.Schema
    ) ||
    isEmpty(
      data?.third_party_response_by_pk?.questionnaireTemplateVersion?.UISchema
    )
  ) {
    throw new Error('Schema or UISchema is empty');
  }

  const onSave =
    (status: Third_Party_Response_Status_Enum) =>
    async (data: ResponseData) => {
      await update({
        variables: {
          Id,
          response: {
            ...data,
            files: undefined,
            newFiles: undefined,
            updatedFiles: undefined,
          },
          status,
        },
      });

      await updateFiles({
        parentId: Id,
        parentType: Parent_Type_Enum.ThirdPartyResponse,
        newFiles: enrichFilesWithPathMetadata(
          data.newFiles as { [key: string]: File[] }
        ),
        originalFiles: mapFilesToFileIds(originalFiles),
        selectedFiles: data.updatedFiles
          ? mapFilesWithMetaToFileIds(
              data.updatedFiles as { [key: string]: FileWithMeta[] }
            )
          : mapFilesToFileIds(originalFiles),
      });

      navigate('/');
    };

  return (
    <PageLayout
      title={`${responseData?.questionnaireTemplateVersion?.parent?.Title}`}
      counter={`(v${responseData?.questionnaireTemplateVersion?.Version})`}
    >
      <QuestionnaireForm
        schema={responseData?.questionnaireTemplateVersion?.Schema}
        uischema={responseData?.questionnaireTemplateVersion?.UISchema}
        values={{
          ...responseData?.ResponseData,
          files: responseData?.files,
        }}
        onCancel={() => navigate('..')}
        onSubmit={onSave(Third_Party_Response_Status_Enum.AwaitingReview)}
        onSave={onSave(Third_Party_Response_Status_Enum.InProgress)}
        readOnly={readOnly}
      />
    </PageLayout>
  );
};

export default Page;
